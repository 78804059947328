.page-navigation {
  .nav-bar {
    &._relative {
      position: relative;
    }

    .title-card-subtitle {
      font-size: 1.25rem;
    }

    .logo {
      width: 5em;
    }

    .nav-bar__select {
      width: 200px;
    }

    .ooni__logo {
      width: 75px;
    }

    .market-logo {
      width: 45px;
      height: 45px;
      margin-right: 5px;
    }
  }

  &__on-top {
    .nav-bar {
      z-index: 60;
    }
  }
}