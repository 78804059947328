@import "../../../../src/scss/variables";

.page-cards {
  .card-icons {
    .btn-circle {
      margin: 10px;

      &_remove {
        &:hover {
          svg {
            fill: $persimmon;
          }
        }
      }
    }
  }
}
