// NEWEST

// blue
$primary: #6075ff;
$conflower-blue: $primary; //@todo replace in apps with $primary or $cornflower-blue
$cornflower-blue: $primary; // 'human' name

$primary-darkest: #323C82;
$chambray: $primary-darkest; // 'human' name

$primary-dark: #4D5ECC;
$indigo: $primary-dark; // 'human' name

$primary-light: #5F74FF;
$primary-lighter: rgba($primary,.125);
$primary-lightest: rgba($primary,.08);

// secondary
$secondary-darkest: #5F6368;
$shuttle-gray: $secondary-darkest;

$secondary-dark: #a2a2a2;
$silver-chalice: $secondary-dark; // 'human' name

$secondary: #cbcbcb;
$table-row-hover: $secondary; // copy
$silver: $secondary; // 'human' name

$secondary-light: #f4f4f4;
$wild-sand: $secondary-light; // 'human' name

$secondary-lighter: #f8f8f8;

// communication
$good: #3F9B34;
$apple: $good; // 'human' name

$include: rgba($good,.5);

$warning: #FFA300;
$orange: $warning; // 'human' name

$bad: #9B3434;
$exclude: rgba($bad,.5);

$danger: #FF5859;
$persimmon: $danger; // 'human' name

// charts
$charts-darkest: #013627;
$charts-dark: #008B69;
$charts: #28BC97;
$charts-light: #68EFC8;
$charts-lightest: #C9FFEF;



// OLD
$melrose: #99a7ff;
$mercury: #e8e8e8;
$titan-white: #dfe4ff;
$deep-cove: #050D42;
$zircon: #f2f5ff;
$fruit-salad: #4caf50;
$moss-green: #a5d6a7;
$valencia: #D93536;

// shades of grey
$dove-gray: #787878;

// alternative
$purple-heart: #6449d7;
$dodger-blue: #1f8efa;
$malibu: #6094ff;
$limed-spruce: #31474b;
$buddha-gold: #d4b000;
$hot-today: #BB7C0D;

// OLD
// Primary colors
$shamrock: #3fd4ad;
$mountain-meadow: #0FB68A;

// Secondary colors
$cornflower-lilac: #ffa1ad;
$green: #1ad801;
$hopbush: #c66fb0;
$limeade: #5a9401;
$aqua-forest: #6da679;

//colors
$alto-1: #e0e0e0;
$alto: #dddddd;
$table-border-color: #d8d8d8;
$nobel: #B5B5B5;
$dusty-gray: #979797;
$mine-shaft: #3D3D3D;
$table-text-color: #2b2b2b;

$tea-green-2: #DCF4CA;
$tusk: #E8F4CA;

$bitter-sweet: #FF6060;
$mona-lisa: #FF9090;

$lochinvar: #2a8f74;
$turquoise: #31d3ae;
$fountain-blue: #5bc0a5;
$cruise: #b2eede;
$tea-green: #cbf4ca;

$sunglow: #ffc732;
$lightning-yellow: #ffbf1c;
$chalky: #f0d9a0;
$cumulus: #ffffda;

$gray: #818181;
$dark-gray: #cbcbcb;

$heliotrope: #ca60ff;

$white: #ffffff;
$black: #000000;

$chip-component-green: #3f9b34;
$chip-component-red: #9b3434;

//breakpoints
$xs: 480;
$sm: 768;
$md: 992;
$lg: 1200;
$xlg: 1280;

//Fonts
$fontLight: 'Lato Light', sans-serif;
$fontMedium: 'Lato Medium', sans-serif;
$fontRegular: 'Lato Regular', sans-serif;
$fontBold: 'Lato Bold', sans-serif;
