@import "variables";
@import "mixins";

%text {
  @include f1-400();
  font-size: 14/16+rem;
  line-height: 17/14+em;
}

.title-main {
  @include f1-900();
  font-size: 3rem;
  text-transform: capitalize;
  color: $dove-gray;
}

.title-main-subtitle {
  @include f1-900();
  font-size: 36 / 16 + rem;
  text-transform: uppercase;
  color: $persimmon;
}

.title-form {
  @include f1-900();
  font-size: 1.25rem;
  text-transform: uppercase;
  color: $shamrock;
}

.title-form-subtitle {
  @include f1-300();
  font-size: 2rem;
  color: #000000;
}

.title-card {
  @include f1-500();
  font-size: 24 / 16 + rem;
  line-height: 33 / 24 +em;
  color: #000000;
}

.title-card-subtitle {
  @include f1-400();
  font-size: 1rem;
  color: $dove-gray;
}

.paragraph {
  margin-bottom: 24/16+rem;
  @include f1-400();
  font-size: 1rem;
  line-height: 34/19+em;
  color: $dove-gray;

  &_large {
    font-size: 24 / 16 + rem;
  }
}

.text-link {
  @extend %text;
  color: #000;
  text-decoration: underline;
}
