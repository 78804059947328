@import "../../../../src/scss/variables";

.drawer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px;
  opacity: 0;
  visibility: hidden;
  width: 0;
  transition: opacity 0.2s, width 0.2s;
  position: fixed;
  z-index: 1;
  max-height: calc(100vh - 72px);
  overflow: auto;
  background: #fff;

  &._static {
    position: static;
  }

  .app__menu-open & {
    width: 250px;
    opacity: 1;
    visibility: visible;

    &._static {
      width: 100%;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  &__list-item {
    width: 100%;
    min-height: 42px;
    white-space: nowrap;
  }

  &__link {
    text-decoration: none;
    padding: 12px;
    border-radius: 2px;
    color: #241c15;
    display: block;
    font-family: 'Lato Regular', sans-serif;

    &.active {
      background-color: $titan-white;
      border-radius: 2px;
      font-family: 'Lato Regular', sans-serif;
    }

    &:hover:not(.active) {
      background-color:$wild-sand;
    }
  }
}
