.buttons {

  &__row {
    > * {
      margin: auto;
    }
  }

  &-page {

    &__toggle {
      user-select: none;
      display: flex;
      align-items: center;
    }

    &__icon-buttons {

      .btn-round,
      .btn-square {

        &__prefix {
          padding: 4/18+em;
        }
      }
    }
  }
}
