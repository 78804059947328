@import "../../../../src/scss/variables";

.highlight {
  &__wrapper {
    position: relative;
  }

  &__copy-button {
    position: absolute;
    right: 1rem;
    top: 1rem;

    > button {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s, visibility 0.2s;
    }
  }

  .copy-to-clipboard__snackbar-action {
    color: $orange;
    cursor: pointer;
  }

  &:hover {
    .highlight__copy-button {
      > button {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
