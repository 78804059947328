@import "../../../../src/scss/variables";

.page__header {
  .page-nav {
    list-style: none;
    margin: 0;
    padding: 10px 0;
    border-bottom: 1px solid $wild-sand;

    &__link {
      color: $dove-gray;
      font-weight: 500;
      padding: 14px 24px 15px 0;
      line-height: 24px;
      float: left;
      font-size: 1rem;
      text-decoration: none;
      font-family: 'Lato Regular', sans-serif;

      &:hover {
        color:#000000;
      }
    }
  }
}
