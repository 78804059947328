@import "../../../../src/scss/variables";
@import "../../../../src/scss/mixins";

.sticky {
  width: 20rem;
  height: 20rem;
  border-left: 1/16+rem solid $wild-sand;
  border-right: 1/16+rem solid $wild-sand;
  border-bottom: 1/16+rem solid $wild-sand;
}
