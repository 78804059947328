@import "../../../../src/scss/variables";

.page-color {
  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .palette {
    position: relative;

    &:hover {
      .palette__copy {
        opacity: 1;
      }
    }

    &__copy {
      font-family: $fontMedium;
      position: absolute;
      padding: 10px 20px;
      color: #000000;
      right: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
      user-select: none;
      transition: opacity 0.3s;
    }

    &__snackbar-action {
      color: $orange;
      cursor: pointer;
    }
  }

  .card__content {
    padding: 0;
  }
}

.palette__input {
  position: fixed;
  top: -100%;
}
