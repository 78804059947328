.datepicker-page {
  .big-date {
    .date-picker {
      max-width: fit-content;

      .form-control {
        min-width: 7.5rem;
      }
    }
  }
}