@import "../../../../src/scss/variables";
@import "../../../../src/scss/mixins";

.page-context-menu {
  .context-zone {
    width: 100%;
    height: 150px;
    border-radius: 5px;
    background-color: $wild-sand;
    display: flex;
    align-items: center;
    justify-content: center;

    &__title {
      @include f1-500();
    }
  }

  .context-menu {
    .list-item {
      cursor: pointer;

      &:hover {
        .svg-icon {
          fill: $black;
        }
      }
    }

    .list-item__title {
      margin-left: 0.625rem;
      @include f1-500();
      color: $dove-gray;
    }
  }
}
