@import "../../../src/scss/variables";
@import "../../../src/scss/mixins";

html,
#app {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Lato Light', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:target {
  padding-top: 64px; //shift for href anchors (for fixed header)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.app-content {
  height: calc(100% - 64px); // 64px - header height
  display: flex;
  margin: 0 auto;
  padding: 64/16+rem 2rem 0;

  &__main {
    max-width: 975px;
    // margin: 0 auto;
    width: 100%;
    transition: width 0.2s, margin 0.2s;

    .app__menu-open & {
      // padding-left: 200px; // drawer width
      margin-left: 250px;
    }
  }
}

.page {
  width: 760px;
  margin: 0 auto;

  > h1 {
    font-size: 3.75rem;
    line-height: 4.5rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fontMedium;
}

h1 {
  @include f1-900();
  font-size: 58/16+rem;
  line-height: 61/50+em;
  color: #000;
}

h2 {
  @include f1-900();
  font-size: 50/16+rem;
  line-height: 61/50+em;
  color: #000;
}

h3 {
  @include f1-500();
  font-size: 42/16+rem;
}

h4 {
  @include f1-500();
  font-size: 34/16+rem;
}

h5 {
  font-size: 26/16+rem;
}

h6 {
  font-size: 18/16+rem;
}

.page {
  padding-bottom: 100px;

  &__header {
    margin: 32px 0;
  }
}

p {
  margin-bottom: 24/16+rem;
  @include f1-400();
  font-size: 1rem;
  line-height: 34/19+em;
  color: $dove-gray;
}

.example {
  padding: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #dedddc;
}

.header-block {
  display: flex;
  align-items: baseline;

  &__subtitle {
    margin-left: 5px;
    font-weight: normal;
  }
}

// Replace code highlight styles
pre {
  font-size: 13px;

  &[class*="language-"] {
    background-color: $wild-sand;
    border-radius: 5px;
  }

  &.line-numbers {
    .line-numbers-rows {
      border-right-color: $dove-gray;

      > span:before {
        color: $dove-gray;
      }
    }
  }
}

code {
  &.inline {
    padding: 0 10px;
  }

  &.red {
    background-color: #ffa2a7;
    color: #851e00;
  }

  &.snippet {
    background-color: $wild-sand;
    padding: 5px 10px;
    border-radius: 2px;
  }
}

.w-100 {
  width: 100%;
}

.text-center {
  text-align: center;
}

.opacity-50 {
  opacity: 0.5;
}

.align-right {
  text-align: right;
}

.text-color {
  &_persimmon {
    color: $persimmon;
  }

  &_orange {
    color: $orange;
  }
}

.cursor {
  &_pointer {
    cursor: pointer;
  }
}

div.toolbar {
  .toolbar-item {
    a {
      color: $black;
      font-family: $fontMedium;
      font-size: 1rem;
      background: none;
      box-shadow: none;
    }
  }
}

.bg-dark {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
}
