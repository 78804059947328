@import "../../../src/scss/variables";

.props-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 1rem;

  tr {
    background-color: #fff;
    border-top: 1px solid $wild-sand;

    &:nth-child(2n) {
      background-color: $wild-sand;
    }

    td:first-child, td:nth-child(2), td:nth-child(3) {
      font-family: monospace;
    }

    td {
      font-size: 14px;
    }
  }

  th,
  td {
    border: 1px solid $wild-sand;
    padding: 6px 13px;
  }

  th {
    font-family: 'Lato Black', sans-serif;
    text-align: left;
  }

  td {
    font-family: 'Lato Regular', sans-serif;
  }

  .required {
    color: $persimmon;
  }
}
