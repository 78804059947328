@import "../../../src/scss/variables";

$height: 144px;

.palette {
  height: $height;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    text-transform: capitalize;
  }
}
