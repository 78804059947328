@import "../../../../src/scss/variables";

.page-table {

  table {

    td {

      &._role {
        min-width: 200px;

        .td-content {
          padding: 0 8px;
        }

        .td-content-inner {
          overflow: visible;
        }

        .select {
          max-width: 180px;
        }
      }

      &._actions {

        .td-content {
          padding: 0 8px;

          .td-content-inner {
            display: flex;
          }
        }

        .btn-circle {
          width: 38px;
          height: 38px;
        }
      }

      &.column2 {
        min-width: 60px;
        max-width: 60px;
        width: 60px;
      }
    }
  }
}

// OLD
.page-table {

  .table {

    &__cell {

      &._actions {
        justify-content: flex-start;
        padding-top: 0;
        padding-bottom: 0;

        .table__cell-hover {
          justify-content: flex-end;
           padding-right: 5px;

          .btn-circle {
            padding: 0 5px;
          }
        }

        .btn-circle {
          padding: 0;
          font-size: 14/16+rem;
          fill: $dove-gray;

          &:hover {
            fill: $black;
          }
        }
      }

      &._role {
        min-width: 200px;
        padding-right: 5px;
        padding-top: 0;
        padding-bottom: 0;

        &_header {
          min-width: 200px;
          padding-left: 20px;
        }

        .table__cell-content {
          padding-left: 20px;
        }

        .form-item {
          width: 100%;
        }

        .select__control__control {
          min-height: 32px;
        }
      }
    }
  }
}
