@import "variables";
@import "mixins";

%link-after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 33%;
  height: 3/16+rem;
  transition: width .3s ease;
}

@mixin link($color) {
  color: $color;

  &:after {
    @extend %link-after;
    background-color: $color;
  }
}

.link {
  position: relative;
  display: inline-block;
  padding-bottom: 10/16+rem;
  @include f1-400();
  font-size: 21/16+rem;
  line-height: 1;
  text-decoration: none;

  @include link($shamrock);

  &_orange {
    @include link($orange);
  }

  &_persimmon {
    @include link($persimmon)
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
}