@import "../../../../src/scss/variables";

$height: 72px;

.header {
  height: $height;
  background-color: #ffffff;
  display: flex;

  &__logo {
    display: flex;
    margin: 0 15px 0 10px;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  .nav-bar {
    z-index: 50;

    .logo {
      width: auto;
    }

    &__container {
      > .logo {
        width: 75px;
        margin-right: 10px;
      }
    }
  }
}
