@import "../../../../src/scss/typography";

.page-dialog {

  .dialog__content,
  .dialog__header {

    h5 {
      @include f1-400();
      font-size: 22/16+rem;
      line-height: 27/22+em;
    }

    p {
      @extend %text;
    }
  }
}
