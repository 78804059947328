@import "../../../../src/scss/variables";
@import "../../../../src/scss/mixins";

.page-select {
  .btn-all {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 29/16+rem;
    padding: 0 15/16+rem;
    border-radius: 29/2/16+rem;
    border: none;
    outline: none;
    background: $wild-sand;
    cursor: pointer;
    @include f1-500();
    line-height: 30/16+rem;
    color: $dove-gray;
    text-transform: capitalize;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:focus {
      outline: none;
    }

    &:not(:last-child) {
      margin-right: 8/16+rem;
    }

    &:hover {
      background: $shamrock;
      color: #fff;
      transition: background .25s, color .25s;
    }

    &._active {
      background: $dove-gray;
      color: #fff;
      transition: background .25s, color .25s;
    }
  }

  .select-all {
    .select__label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 5px;
    }
  }
}
