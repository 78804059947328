@import "variables";

.skeleton-block {
  display: block;
  height: 28/16 + rem;
  border-radius: 5/16 + rem;
  background-image: linear-gradient(
      90deg,
      $wild-sand 0px,
      $alto 2rem,
      $wild-sand 4rem
  );
  background-size: 200%;
  background-position: 100%;
  animation: skeleton 1.5s infinite linear;
}

@keyframes skeleton {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: -100%;
  }
}
