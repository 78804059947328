.login-form {
  max-width: 622px;
  width: 100%;

  .form__content {
    max-width: 29.125rem;
  }

  .text-field {
    margin-bottom: 1.75rem;
  }
}

.text-field-page__high-textarea {

  textarea {
    height: 100px !important;
  }
}
